@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Avenir", sans-serif;
  }
}

body {
    font-family: Arial, sans-serif;
    background: linear-gradient(to bottom right, #1a1a2e, #16213e);
    color: white;
    text-align: center;
    padding: 20px;
}
.container {
    max-width: 600px;
    margin: auto;
    
}
.hero h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
}
.hero p {
    font-size: 1.2em;
    margin-bottom: 20px;
}
.input-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}
.email-input {
    padding: 10px;
    width: 70%;
    border: none;
    border-radius: 5px;
}
.join-btn {
    background-color: #ff4d6d;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}
.join-btn:hover {
    background-color: #ff1f50;
}
.features {
    margin-top: 40px;
}
.feature {
    margin-bottom: 20px;
}
.cta {
    margin-top: 40px;
}
